import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

import { interval, Subscription } from 'rxjs';
import Quill from 'quill';

import { SanitizeHtmlPipe } from '../../../pipes';
import { ValidationMessageService } from '../../../services/common';
import { ValueDefinitionSize } from '../../../models';

let nextId = 0;

@Component({
  selector: 'lib-rich-text-editor-input',
  templateUrl: './rich-text-editor-input.component.html',
  styleUrls: ['./rich-text-editor-input.component.scss'],
  providers: [SanitizeHtmlPipe],
})
export class RichTextEditorInputComponent implements OnInit, OnDestroy {
  @Input() autofocus: boolean = false;
  @Input() autoSaveInterval?: number;
  @Input() control?: FormControl;
  @Input() hint?: string;
  @Input() label: string = '';
  @Input() messages?: ValidationErrors;
  @Input() placeholder?: string;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;

  // Reflecting the native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<void>();

  public readonly _inputId = `rich-text-input-${nextId++}`;
  public errorMessages: ValidationErrors = {};
  public tempControl = new FormControl<string>('');

  private autoSaveSubscription?: Subscription;
  private updatedControlValue: string | null = '';
  private valueChangesSubscription?: Subscription;

  constructor(
    private readonly sanitizeHtml: SanitizeHtmlPipe,
    private readonly validationMessageService: ValidationMessageService,
  ) {}

  public ngOnInit(): void {
    this.updatedControlValue = this.control?.value ?? '';
    this.tempControl.setValue(this.updatedControlValue);
    this.errorMessages = { ...this.validationMessageService.validationMessages, ...this.messages };
    this.valueChangesSubscription = this.control?.valueChanges.subscribe((updatedControlValue: string | null) => {
      this.updatedControlValue = updatedControlValue;
    });
  }

  public ngOnDestroy(): void {
    this.onBlur();
    this.valueChangesSubscription?.unsubscribe();
  }

  public onEditorInit(event: { editor: Quill }): void {
    if (this.autofocus) {
      event.editor.focus();
    }
  }

  public onEditorSelectionChange(event: { range: { index: number; length: number } | null }): void {
    if (this.control?.disabled) {
      return;
    }

    if (event.range) {
      this.onFocus();
    } else {
      this.onBlur();
    }
  }

  public onEditorTextChange(event: { htmlValue: null | string }): void {
    this.updatedControlValue = event.htmlValue ? this.sanitizeHtml.transform(event.htmlValue) : null;
    this.control?.markAsDirty();
  }

  private onBlur(): void {
    this.updateControlValue();
    this.autoSaveSubscription?.unsubscribe();
  }

  private onFocus(): void {
    if (this.autoSaveInterval) {
      this.autoSaveSubscription = interval(this.autoSaveInterval).subscribe(() => {
        this.updateControlValue();
      });
    }
  }

  private updateControlValue(): void {
    if (
      this.control?.value !== this.updatedControlValue &&
      !(!this.control?.value && this.updatedControlValue && this.updatedControlValue.length <= 0)
    ) {
      this.control?.setValue(this.updatedControlValue);
    }
    this.blur.emit();
  }
}
