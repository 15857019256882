import { Injectable } from '@angular/core';
import { finalize, take } from 'rxjs';
import { DeactivateEntityTypes, ValueDefinition, ValueDefinitionGroup, Metric } from '../../../models';
import { AlertsService, TranslateService } from '../../../services/common';
import { MetricApiService } from '../../../services/types';
import { MetricStructureStateService } from '../metric-structure-state.service';
import { MetricTableGroup } from '../../models';

@Injectable()
export class DeactivateEntityService {
  constructor(
    private metricStructureService: MetricStructureStateService,
    private metricsService: MetricApiService,
    private translateService: TranslateService,
    private alertsService: AlertsService,
  ) {}

  public deactivate(
    deactivationEntityType: DeactivateEntityTypes,
    metricId: string,
    valueDefinition?: ValueDefinition,
    valueDefinitionGroup?: ValueDefinitionGroup,
    metricTableGroup?: MetricTableGroup,
  ): void {
    this.updateFieldStatus(true);
    this.deactivateEntity(deactivationEntityType, metricId, valueDefinition, valueDefinitionGroup, metricTableGroup);
  }

  private deactivateValueDefinition(metricId: string, valueDefinition: ValueDefinition): void {
    this.updateFieldStatus(true);
    this.metricsService
      .deactivateValueDefinition(metricId, valueDefinition.value_definition_group_id, valueDefinition.id)
      .pipe(
        take(1),
        finalize(() => this.updateFieldStatus(false)),
      )
      .subscribe((metric) => {
        this.metricStructureService.updateMetric(metric.data);
        const updatedValueDefinition = this.getValueDefinitionFromMetric(valueDefinition.id, metric.data);
        this.metricStructureService.updateSelectedItem({
          ...valueDefinition,
          active: updatedValueDefinition?.active,
          end_fiscal_year_frequency_code: updatedValueDefinition?.end_fiscal_year_frequency_code,
        });

        this.alertsService.success(
          this.translateService.instant(
            `Field deactivated from ${updatedValueDefinition?.end_fiscal_year_frequency_code}`,
          ),
        );
      });
  }

  private deactivateMetricTableGroup(metricId: string, metricTableGroup: MetricTableGroup): void {
    this.updateFieldStatus(true);
    this.metricsService
      .deactivateMetricTable(metricId, metricTableGroup.table_id)
      .pipe(
        take(1),
        finalize(() => this.updateFieldStatus(false)),
      )
      .subscribe((metric) => {
        this.metricStructureService.updateMetric({
          ...metric.data,
          value_definition_groups: metric.data.value_definition_groups,
        });

        const end_fiscal_year_frequency_code = metric.data.value_definition_groups?.find(
          (vdg) => vdg.table_id == metricTableGroup.id,
        )?.end_fiscal_year_frequency_code;

        this.metricStructureService.updateSelectedItem({
          ...metricTableGroup,
          active: false,
          end_fiscal_year_frequency_code: end_fiscal_year_frequency_code
            ? end_fiscal_year_frequency_code
            : metricTableGroup.end_fiscal_year_frequency_code,
        });

        this.alertsService.success(
          this.translateService.instant(`Table deactivated from ${end_fiscal_year_frequency_code}`),
        );
      });
  }

  private getValueDefinitionFromMetric(vdId: string, metric: Metric): ValueDefinition | undefined {
    return metric.value_definition_groups
      ?.flatMap((x) => x.value_definitions?.flatMap((y) => y))
      .find((vd) => vd?.id === vdId);
  }

  private updateFieldStatus(updating: boolean): void {
    this.metricStructureService.updateIsMetricUpdating(updating);
  }

  private deactivateEntity(
    deactivationEntityType: DeactivateEntityTypes,
    metricId: string,
    valueDefinition?: ValueDefinition,
    valueDefinitionGroup?: ValueDefinitionGroup,
    metricTableGroup?: MetricTableGroup,
  ): void {
    if (deactivationEntityType === DeactivateEntityTypes.FIELD && valueDefinition) {
      this.deactivateValueDefinition(metricId, valueDefinition);
    } else if (deactivationEntityType === DeactivateEntityTypes.TABLE && metricTableGroup) {
      this.deactivateMetricTableGroup(metricId, metricTableGroup);
    }
  }
}
