import { Pipe, PipeTransform } from '@angular/core';
import { MetricFieldVisibility, MetricFieldVisibilityPerFiscalYear } from '../../../../../../models';
import { TranslateService } from '../../../../../../services/common';

@Pipe({
  name: 'getHiddenByTaxonomyMsg',
})
export class GetHiddenByTaxonomyMsgPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(
    selectedItemId?: string,
    fieldVisibilityPerFY: MetricFieldVisibilityPerFiscalYear[] = [],
    isValueDefinition?: boolean,
  ): string | undefined {
    const hiddenInFys = fieldVisibilityPerFY
      .filter((fvByFy) => this.findHiddenSelectedItem(fvByFy.field_visibility, isValueDefinition, selectedItemId))
      .map((fvByFy) => fvByFy.fiscal_year_frequency_code);

    hiddenInFys.sort();

    return hiddenInFys.length
      ? this.translateService.instant(
          'Deactivated for the following fiscal years based on published framework taxonomy selection: {fiscalYears}',
          { fiscalYears: hiddenInFys.join(', ') },
        )
      : undefined;
  }

  private findHiddenSelectedItem(
    fieldVisibility: MetricFieldVisibility[],
    isValueDefinition?: boolean,
    selectedItemId?: string,
  ): MetricFieldVisibility | undefined {
    if (isValueDefinition) {
      return fieldVisibility.find((fv) => fv.value_definition_id === selectedItemId && !fv.visibility);
    }
    return fieldVisibility.find((fv) => fv.metric_table_definition_id === selectedItemId && !fv.visibility);
  }
}
