import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import {
  CalculatedTypeDetails,
  FocusedFieldAction,
  Frequency,
  NumberTypeDetails,
  Period,
  SOURCE_CONFIGURATION,
  Value,
  ValueDefinitionSize,
  ValueGroup,
  YearToDateCalculationLabel,
} from '../../../models';
import { ValueFormControl } from '../../models/valueFormControl';
import { UnitsService } from '../../../units';
import { BaseMetricEditorFormStateService } from '../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ValueDefinitionService } from '../../services/value-definition.service';
import { ValueGroupFormGroup } from '../../models/valueGroupFormGroup';
import { MetricEditorNumericFieldComponent } from '../metric-editor-numeric-field/metric-editor-numeric-field.component';
import { FormatFieldUniqueIdPipe } from '../../../pipes';
import { ObservableUtils } from '../../../classes';
import { MetricEditorFrequencyService } from '../../services/metric-editor-frequency/metric-editor-frequency.service';

@Component({
  selector: 'lib-metric-editor-frequency-field',
  templateUrl: './metric-editor-frequency-field.component.html',
  styleUrls: ['./metric-editor-frequency-field.component.scss'],
  providers: [FormatFieldUniqueIdPipe],
})
export class MetricEditorFrequencyFieldComponent implements OnInit, OnChanges {
  @Input({ required: true }) control!: ValueFormControl<NumberTypeDetails | CalculatedTypeDetails>;
  @Input({ required: true }) valueGroupFormGroup!: ValueGroupFormGroup;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input({ required: true }) valueGroup!: ValueGroup;

  @Input() fiscalYearPeriod?: Period;
  @Input() frequenciesControl?: ValueFormControl[];
  @Input() forceAllowDecimals = false;

  // Reflecting the native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<FocusEvent>();

  @ViewChild('valueFieldRef') valueFieldRef?: MetricEditorNumericFieldComponent;

  hint = '';
  indicatorName = '';
  unit = '';
  maxDecimals?: number;
  yearToDateCalculation: string = '';
  frequency: Frequency | undefined = Frequency.YEARLY;
  isFocusable: boolean = false;
  isFocusEnabled: boolean = false;

  focusedField$: Observable<Value | undefined>;
  focusFieldUniqueId$: Observable<string>;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly unitsService: UnitsService,
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
    private readonly valueDefinitionService: ValueDefinitionService,
    private readonly formatFieldUniqueId: FormatFieldUniqueIdPipe,
    private readonly metricEditorFrequencyService: MetricEditorFrequencyService,
  ) {
    this.focusedField$ = this.baseMetricEditorFormStateService.focusedField$.pipe(takeUntil(this.destroy$));
    this.focusFieldUniqueId$ = this.baseMetricEditorFormStateService.focusFieldUniqueId$.pipe(takeUntil(this.destroy$));
  }

  ngOnInit(): void {
    if (this.control) {
      this.indicatorName = this.control.valueRef.label ?? '';
      const ytdCalcKey = this.control.valueRef.value_definition_frequency?.year_to_date_calculation;
      this.yearToDateCalculation = ytdCalcKey ? YearToDateCalculationLabel[ytdCalcKey] : '';
      this.frequency = this.control.valueRef.value_definition_frequency?.frequency;
      this.hint = this.control.valueRef.hint ?? '';
      this.unit = this.getUnitSymbol(this.control.valueRef.type_details.units);
      this.maxDecimals = this.control.valueRef.type_details.max_decimals;
      this.isFocusable = this.valueDefinitionService.isFocusableDisplayField(this.control.valueRef);
    }
    this.isFocusEnabled = this.baseMetricEditorFormStateService.enableFocus;
  }

  ngOnChanges(): void {
    this.frequenciesControl?.sort(
      (a, b) =>
        new Date(a.valueRef.fiscal_year_period!.start).getTime() -
        new Date(b.valueRef.fiscal_year_period!.start).getTime(),
    );

    this.frequenciesControl?.forEach((frequencyControl) => {
      this.setupFocusHandler(frequencyControl);
      this.setupPeriodAvailability(frequencyControl);
    });
  }

  private getUnitSymbol(code: string | undefined): string {
    if (code) {
      const unit = this.unitsService.getUnit(code);
      return unit?.symbol ?? '';
    }
    return '';
  }

  public selectFieldForFocus(
    focusId: string,
    value: Value,
    valueGroup: ValueGroup,
    focusedFieldAction?: FocusedFieldAction,
  ): void {
    if (this.isFocusable) {
      this.baseMetricEditorFormStateService.setFocusFieldAndUniqueId(focusId, value, valueGroup);
      this.baseMetricEditorFormStateService.setFocusedFieldAction(focusedFieldAction);
    }
  }

  public setFocus(): void {
    if (this.valueDefinitionService.isFocusableDisplayField(this.control.valueRef)) {
      this.valueFieldRef?.setFocus();
    }
  }

  private setupPeriodAvailability(frequencyControl: ValueFormControl): void {
    if (!this.fiscalYearPeriod || !frequencyControl.valueRef.fiscal_year_period?.period) {
      return;
    }

    if (
      !this.metricEditorFrequencyService.isPeriodEnabled(
        this.fiscalYearPeriod,
        frequencyControl.valueRef.fiscal_year_period.period,
      )
    ) {
      frequencyControl.disable();
    }
  }

  private setupFocusHandler(frequencyControl: ValueFormControl) {
    frequencyControl.focusValue$
      .pipe(ObservableUtils.filterNullish())
      .pipe(takeUntil(this.destroy$))
      .subscribe((focusValue) =>
        this.selectFieldForFocus(
          this.formatFieldUniqueId.transform(this.valueGroupFormGroup.valueGroupRef, frequencyControl.valueRef),
          focusValue,
          this.valueGroup,
        ),
      );
  }

  protected readonly ValueDefinitionSize = ValueDefinitionSize;
}
