<ng-container *libFeatureFlag="{ featureFlags: ['new_rich_text_editor_enabled'] }">
  <lib-rich-text-editor-input
    [label]="label"
    [control]="control"
    [messages]="messages"
    [hint]="hint"
    [placeholder]="placeholder"
    [autofocus]="autofocus"
    [size]="size"
    [autoSaveInterval]="autoSaveInterval"
    (blur)="onBlur()"
    (focus)="onFocus()"
  >
  </lib-rich-text-editor-input>
</ng-container>

<ng-container *libFeatureFlag="{ featureFlags: ['new_rich_text_editor_enabled'], hiddenWhenEnabled: true }">
  <div
    *ngIf="control"
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [dataTestId]="'rich-text-input'"
  >
    <lib-form-field-label [label]="label" [control]="control" [id]="_inputId"></lib-form-field-label>
    <div class="rich-text-formfield">
      <ejs-richtexteditor
        #richTextEditorComponent
        libFocusState
        libStripNonPrintableCharacters
        class="editor rich-text-editor"
        [ngClass]="{
          error: control.touched && control.invalid,
        }"
        [formControl]="control"
        [toolbarSettings]="editorToolbarConfig"
        [placeholder]="placeholder"
        [readonly]="control.disabled"
        [attr.aria-labelledby]="_inputId"
        [htmlAttributes]="{ 'data-testid': 'rich-text-editor' }"
        [pasteCleanupSettings]="pasteCleanupSettings"
        (change)="onChange($event)"
        (libBeforeUnload)="onBlur()"
        (blur)="onBlur()"
        (focus)="onFocus()"
      >
      </ejs-richtexteditor>
    </div>

    <mat-hint *ngIf="(control.valid || control.untouched) && hint">{{ hint }}</mat-hint>
    <ng-container *ngIf="control.invalid && control.touched">
      <mat-error class="color-error" *ngFor="let error of control.errors | errorKeys">
        {{ errorMessages[error] }}
      </mat-error>
    </ng-container>
  </div>
</ng-container>
