<div *ngIf="control" libFormField [size]="size" [control]="control" [dataTestId]="'rich-text-editor-input'">
  <lib-form-field-label [label]="label" [control]="control" [id]="_inputId"></lib-form-field-label>
  <div id="quill-editor-container" class="rich-text-formfield">
    <p-editor
      [attr.aria-labelledby]="_inputId"
      [formControl]="tempControl"
      [placeholder]="placeholder"
      [readonly]="control.disabled"
      [bounds]="'#quill-editor-container'"
      (onInit)="onEditorInit($event)"
      (onSelectionChange)="onEditorSelectionChange($event)"
      (onTextChange)="onEditorTextChange($event)"
    >
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button type="button" class="ql-list" value="bullet" aria-label="Unordered List"></button>
          <button type="button" class="ql-list" value="ordered" aria-label="Ordered List"></button>
          <button type="button" class="ql-link" aria-label="Insert Link"></button>
        </span>
      </ng-template>
    </p-editor>
  </div>

  <mat-hint *ngIf="hint && (control.valid || control.untouched)">{{ hint }}</mat-hint>
  <ng-container *ngIf="control.invalid && control.touched">
    <mat-error *ngFor="let error of control.errors | errorKeys"> {{ errorMessages[error] }}</mat-error>
  </ng-container>
</div>
