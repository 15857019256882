import { Injectable } from '@angular/core';
import { filter, finalize, Observable, switchMap, take, tap } from 'rxjs';
import { ApiResponse, Metric, Status, ValueDefinition, ValueDefinitionType } from '../../../models';
import { AlertsService, TranslateService } from '../../../services/common';
import { MetricApiService } from '../../../services/types';
import { MetricStructureStateService } from '../metric-structure-state.service';
import { MetricTableGroup } from '../../models';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { ObservableUtils } from '../../../classes';

@Injectable()
export class ActivateEntityService {
  constructor(
    private metricsService: MetricApiService,
    private metricStructureService: MetricStructureStateService,
    private alertsService: AlertsService,
    private translateService: TranslateService,
    private dialogsService: DialogsService,
  ) {}

  activateValueDefinition(metricId: string, valueDefinition: ValueDefinition): Observable<ApiResponse<Metric>> {
    if (valueDefinition.type === ValueDefinitionType.calculated) {
      return this.dialogsService
        .open(ConfirmationDialogComponent, {
          data: {
            title: this.translateService.instant('Activate field'),
            warningMsg: this.translateService.instant(
              'If this metric has been marked as complete in an open fiscal year, the status will be set back to in progress as a result of modifying the calculated field.',
            ),
            primaryBtn: this.translateService.instant('Confirm'),
          },
        })
        .afterClosed()
        .pipe(
          ObservableUtils.filterNullish(),
          filter((result) => result.status === Status.CONFIRMED),
          switchMap(() =>
            this.metricsService
              .activateValueDefinition(metricId, valueDefinition.value_definition_group_id, valueDefinition.id)
              .pipe(
                tap((response) => {
                  this.metricStructureService.updateMetric(response.data);
                  this.metricStructureService.updateSelectedItem({
                    ...valueDefinition,
                    active: true,
                    end_fiscal_year_frequency_code: undefined,
                  });
                  this.alertsService.success(this.translateService.instant('Field reactivated'));
                }),
              ),
          ),
        );
    } else {
      return this.metricsService
        .activateValueDefinition(metricId, valueDefinition.value_definition_group_id, valueDefinition.id)
        .pipe(
          tap((response) => {
            this.metricStructureService.updateMetric(response.data);
            this.metricStructureService.updateSelectedItem({
              ...valueDefinition,
              active: true,
              end_fiscal_year_frequency_code: undefined,
            });
            this.alertsService.success(this.translateService.instant('Field reactivated'));
          }),
        );
    }
  }

  activateMetricTable(metricTableGroup: MetricTableGroup): void {
    this.metricStructureService.updateIsMetricUpdating(true);
    this.metricsService
      .activateMetricTable(metricTableGroup.metric_id, metricTableGroup.table_id)
      .pipe(
        take(1),
        finalize(() => this.metricStructureService.updateIsMetricUpdating(false)),
      )
      .subscribe((metricResponse) => {
        this.metricStructureService.updateMetric(metricResponse.data);

        this.metricStructureService.updateSelectedItem({
          ...metricTableGroup,
          active: true,
          end_fiscal_year_frequency_code: undefined,
        });

        this.alertsService.success(this.translateService.instant('Metric table activated'));
      });
  }
}
