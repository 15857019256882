import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { filter, Observable } from 'rxjs';

import {
  ConfirmationDialogConfig,
  DialogResult,
  Metric,
  MetricTableColumn,
  MetricTableColumnDefinitionUpsert,
  MetricTableDefinition,
  OptionListOption,
  Status,
  ValueDefinitionType,
} from '../../../../../models';
import { ConfirmationDialogComponent, DialogsService } from '../../../../../dialogs';
import { TableContextColumnDialogForm } from './table-context-column-dialog-form';
import { TranslateService } from '../../../../../services/common';
import { TrimMethod } from '../../../../../directives/trim-whitespace/trim-whitespace.model';
import { MetricStructureStateService } from '../../../../services/metric-structure-state.service';
import { MetricStructureUtils } from '../../../../classes/MetricStructureUtils/metric-structure-utils';
import { FeatureFlagService } from '../../../../../feature-flag';

export interface TableContextColumnDialogComponentConfig {
  metricTableDefinition: MetricTableDefinition;
  metricStructureService: MetricStructureStateService;
  column?: MetricTableColumn;
  columns: MetricTableColumnDefinitionUpsert[];
  maxRowCount?: number;
  metric: Metric;
}

@Component({
  selector: 'lib-table-context-column-dialog',
  templateUrl: './table-context-column-dialog.component.html',
  styleUrls: ['./table-context-column-dialog.component.scss'],
})
export class TableContextColumnDialogComponent {
  readonly eTrimMethod: typeof TrimMethod = TrimMethod;

  public nonDeletableColumnIds: string[] = [];
  public tableContextColumnDialogForm: TableContextColumnDialogForm;
  public isMetricUpdating$?: Observable<boolean | undefined>;

  metricTableDeactivationEnabled: boolean = false;

  constructor(
    private readonly dialogsService: DialogsService,
    private readonly translateService: TranslateService,
    private dialogRef: MatDialogRef<TableContextColumnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: TableContextColumnDialogComponentConfig,
    private readonly featureFlagService: FeatureFlagService,
  ) {
    this.metricTableDeactivationEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled([
      'metric_structure_metric_table_deactivation_enabled',
    ]);
    this.tableContextColumnDialogForm = new TableContextColumnDialogForm(
      data.columns,
      data.column,
      data.maxRowCount,
      data.metric,
      data.metricStructureService.isAdmin,
      this.metricTableDeactivationEnabled,
    );
    this.initializeDisabledDeleteColumns();
    this.isMetricUpdating$ = this.data.metricStructureService.isMetricUpdating$;
  }

  public save(): void {
    const result = this.tableContextColumnDialogForm.toModel();
    if (this.data.column?.id) {
      this.data.metricStructureService.updateMetricTableColumnDefinition(
        this.data.metricTableDefinition,
        this.data.column.id,
        result,
        this.closeSuccess,
      );
    } else {
      this.data.metricStructureService.createMetricTableColumnDefinition(
        this.data.metricTableDefinition,
        { label: result.label, options: result.options.map((o) => o.label), type: ValueDefinitionType.label },
        this.closeSuccess,
      );
    }
  }

  public closeDialog(): void {
    if (!this.tableContextColumnDialogForm.dirty) {
      this.dialogRef.close({ status: Status.CANCEL });
      return;
    }

    const dialogRef = this.dialogsService.open<ConfirmationDialogComponent, ConfirmationDialogConfig>(
      ConfirmationDialogComponent,
      {
        data: {
          primaryBtn: this.translateService.instant('Continue'),
          title: this.translateService.instant('Cancel action'),
          warningMsg: this.translateService.instant(
            'Are you sure you wish to cancel this action? You will lose your changes.',
          ),
        },
      },
    );

    dialogRef
      .afterClosed()
      .pipe(filter((result?: DialogResult) => result?.status === Status.CONFIRMED))
      .subscribe(() => {
        this.dialogRef.close({ status: Status.CANCEL });
      });
  }

  public optionsChange(options: OptionListOption[]): void {
    const newOptionIds = options.filter((o) => o.id).map((o) => o.id);
    const currentOptions = this.tableContextColumnDialogForm.controls.options.value.filter((o) => o.id);
    const deletedOptionId = currentOptions.map((o) => o.id).find((id) => !newOptionIds.includes(id));

    if (deletedOptionId && this.data.column?.id) {
      this.data.metricStructureService.deleteMetricTableColumnOption(
        this.data.metricTableDefinition,
        this.data.column.id,
        deletedOptionId,
      );

      if (newOptionIds.length === 1) {
        this.nonDeletableColumnIds = [String(newOptionIds[0])];
      }
    }

    this.tableContextColumnDialogForm.updateOptions(options, !deletedOptionId);
  }

  private closeSuccess = () => {
    this.dialogRef.close({ status: Status.SUCCESS });
  };

  private initializeDisabledDeleteColumns(): void {
    if (this.data.column) {
      const metricTableDefinition = this.data.metricTableDefinition;
      if (metricTableDefinition.published) {
        this.nonDeletableColumnIds = this.data.column.context_options?.map((o) => o.id) ?? [];
      } else {
        const filterValues = metricTableDefinition.calculation_definitions
          .flatMap((d) => d.type_details.formula.filters)
          .filter((f) => f.column === this.data.column?.label)
          .flatMap((f) => f.values);
        const coreOptions =
          MetricStructureUtils.isCoreTableColumnOfRefV2OrTPMInPlatform(
            this.data.metric,
            this.data.column,
            this.data.metricStructureService.isAdmin,
          ) && this.metricTableDeactivationEnabled
            ? this.data.column.context_options
                ?.filter((option) => !!option.core_metric_table_column_option_id)
                .map((o) => o.label) || []
            : [];
        const disabledOptions =
          this.data.column.context_options?.length === 1
            ? this.data.column.context_options
            : this.data.column.context_options?.filter(
                (o) => filterValues.includes(o.label) || coreOptions.includes(o.label) || o.has_values,
              );

        this.nonDeletableColumnIds = disabledOptions?.map((option) => option.id) || [];
      }
    }
  }
}
